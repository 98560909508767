import { axiosInstance } from '@/utils/axios';
import axios from 'axios';

class GlobalService {
  async getEnum() {
    return await axiosInstance.get('misc/enums');
  }
  async getApplicationStatusCount() {
    return await axiosInstance.get('employer/applications-count');
  }
  async getApplicationOverviewCount() {
    return await axiosInstance.get('employer/track-applications/overview');
  }
  async getAllApplication(params) {
    return await axiosInstance.get('employer/track-applications', { params });
  }
  async updateApplication(data) {
    return await axiosInstance.put(`employer/track-applications/${data.id}/status`, { status: data.status, send_mail: data.send_mail });
  }
  async getJobTitles() {
    return await axiosInstance.get('misc/job-titles');
  }
  async getJobCategories() {
    return await axiosInstance.get('misc/job-categories');
  }
  async getCountries() {
    return await axiosInstance.get('misc/countries');
  }
  async getStates(country) {
    return await axiosInstance.get(`misc/countries/${country}/states`);
  }
  async getCompanyCategory() {
    return await axiosInstance.get('misc/company-categories');
  }

  async getAllTalents(payload) {
    return await axiosInstance.get('employer/talent-pools', { params: payload?.params });
  }
  async getSingleTalent(id) {
    return await axiosInstance.get(`employer/talent-pools/${id}`);
  }
  async getAllTalentPool(payload) {
    return await axiosInstance.get('employer/talent-pools', { params: payload?.params });
  }
  async getAllJobs(payload) {
    return await axiosInstance.get('employer/jobs', { params: payload?.params });
  }
  async getSingleJob(id) {
    return await axiosInstance.get(`employer/jobs/${id}`);
  }
  async getSingleJobApplications(id) {
    return await axiosInstance.get(`employer/jobs/${id}/applications`);
  }
  async getTalentJobApplication(data) {
    return await axiosInstance.get(`employer/jobs/${data.jobId}/applications/${data.applicationId}`);
  }
  async addApplicationEvaluation({ jobId, applicationId, ...data }) {
    return await axiosInstance.put(`/employer/jobs/${jobId}/applications/${applicationId}`, data);
  }
  async getJobOverviewCount() {
    return await axiosInstance.get('employer/jobs/overview');
  }
  async shareTalentProfile(data) {
    return await axiosInstance.post(`/employer/candidates/profile/share`, data);
  }
  async emailTalent(data) {
    return await axiosInstance.post(`/employer/candidates/${data.id}/email/send`, data.details);
  }

  async createJob(data) {
    return await axiosInstance.post('employer/jobs', data);
  }
  async updateSingleJob(data) {
    return await axiosInstance.put(`employer/jobs/${data.id}`, data.details);
  }
  async updateSingleJobStatus({ id, status, message }) {
    return await axiosInstance.put(`employer/jobs/${id}/status`, { status, message });
  }
  async deleteSingleJob(id) {
    return await axiosInstance.delete(`employer/jobs/${id}`);
  }
  async inviteToJob(data) {
    return await axiosInstance.post(`employer/jobs/${data.id}/invites`, data.details);
  }
  async hireApplicant(data) {
    return await axiosInstance.post(`employer/track-applications/${data.id}/hire`, data.data);
  }
  async scheduleInterview(data) {
    return await axiosInstance.post(`employer/track-applications/${data.id}/interview`, data);
  }
  async rejectApplicant(data) {
    return await axiosInstance.post(`employer/track-applications/${data.id}/reject`, data);
  }
  async getAllConversations() {
    return await axiosInstance.get('employer/conversations');
  }
  async getConversationMessage(id) {
    return await axiosInstance.get(`employer/conversations/${id}/messages`);
  }
  async createConversation(payload) {
    return await axiosInstance.post(`employer/conversations`, payload);
  }
  async getSubscriptionPlans() {
    return await axiosInstance.get(`misc/subscription-plans`);
  }
  async getPaymentProcessors() {
    return await axiosInstance.get(`employer/payments`);
  }
  async makePayment(payload) {
    return await axiosInstance.post(`employer/payments?payment_gateway_type=${payload.processor}`, payload.plan);
  }
  async updatePayment(payload) {
    return await axiosInstance.put(`employer/payments/${payload.payment_reference}?payment_gateway_type=${payload.payment_gateway_type}`, payload);
  }
  async getPaymentProcessor(payload) {
    return await axiosInstance.get(`employer/payments/${payload.uuid}?payment_gateway_type=${payload.payment_gateway_type}`);
  }
  async getSubscriptionPayments() {
    return await axiosInstance.get(`employer/subscriptions`);
  }
  async getSettings() {
    return await axiosInstance.get('misc/app-settings');
  }
  async exportJobOpenings() {
    return await axiosInstance.get('employer/export/job-openings');
  }
  async jobInvite(params) {
    return await axiosInstance.post(`employer/job-invites`, params);
  }
  async jobInviteManual(params) {
    return await axiosInstance.post(`employer/jobs/${params.jobId}/invites`, params.data);
  }
  async jobInviteCSV(params) {
    return await axiosInstance.post(`employer/jobs/${params.jobId}/invite/import`, params.data);
  }
  async getFeatures() {
    return await axiosInstance.get(`admin/features`);
  }
  async getConversionRate() {
    return await axios.get(`https://api.currencybeacon.com/v1/convert?api_key=${process.env.VUE_APP_CONVERT_API_KEY}&from=USD&to=NGN&amount=1`);
  }
  async uploadFileUrl(data) {
    return await axiosInstance.post(`employer/upload-file`, data);
  }
  async getInfoFromResumeParser(data) {
    return await axios.get(`https://api.apilayer.com/resume_parser/url?url=${data}`, {
      headers: {
        apikey: process.env.VUE_APP_API_LAYER_KEY,
      },
    });
  }
  async getApplicationStatuses() {
    return await axiosInstance.get('admin/misc/application-statuses');
  }
}
export default new GlobalService();
